// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-ad-js": () => import("/opt/build/repo/src/pages/products-ad.js" /* webpackChunkName: "component---src-pages-products-ad-js" */),
  "component---src-pages-products-combined-js": () => import("/opt/build/repo/src/pages/products-combined.js" /* webpackChunkName: "component---src-pages-products-combined-js" */),
  "component---src-pages-products-hospitality-js": () => import("/opt/build/repo/src/pages/products-hospitality.js" /* webpackChunkName: "component---src-pages-products-hospitality-js" */),
  "component---src-pages-products-point-of-purchase-js": () => import("/opt/build/repo/src/pages/products-point-of-purchase.js" /* webpackChunkName: "component---src-pages-products-point-of-purchase-js" */),
  "component---src-pages-products-restaurant-js": () => import("/opt/build/repo/src/pages/products-restaurant.js" /* webpackChunkName: "component---src-pages-products-restaurant-js" */),
  "component---src-pages-products-retail-js": () => import("/opt/build/repo/src/pages/products-retail.js" /* webpackChunkName: "component---src-pages-products-retail-js" */)
}

